import React, { useEffect, useState } from 'react';
import styled from "styled-components";

import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_FETCH_WEB_API_LOG,
  SG_GET_ORGANIZATION_SELECT,
  SG_GET_WEB_API_CRED_LIST,
  SG_EDIT_WEB_API_LOG,
  SG_ADD_WEB_API_LOG,


} from "constants/actions";
//
import { Dimmer, Loader, Segment, Icon, Message, Button, Header, List, Input, Confirm } from 'semantic-ui-react'
//
import { format } from 'date-fns'
//
import Select from "react-select";
import { BasicErrorMessage } from "utilities/ErrorMessages"
//
const Details = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateId, setTemplateId] = useState(false)
  const [templateDetails, setTemplateDetails] = useState({
    name: null
  });
  const [orgSelected, setOrgSelected] = useState([])

  const [loading, setLoading] = useState(false)

  const [apiCreds, setApiCreds] = useState([])
  const [apiCredSelected, setApiCredSelected] = useState([])

  const [apiParam, setApiParam] = useState('')

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true)
  const [valueChanged, setValueChanged] = useState(false)

  const [startProcess, setStartProcess] = useState(false)
  const [startImport, setStartImport] = useState(false)

  function BackArrow() {
    navigate(`/app/web-api-log`)
  }

  useEffect(() => {
    setTemplateId(id)
  }, [id]);

  useEffect(() => {
    if (templateId > 0) {
      dispatch({
        type: SG_FETCH_WEB_API_LOG,
        payload: { id: templateId }
      });
    }
  }, [dispatch, templateId]);

  useEffect(() => {
    if (orgSelected) {
      dispatch({
        type: SG_GET_WEB_API_CRED_LIST,
        payload: `${orgSelected?.id ? `organization=${orgSelected?.id}` : ''}`,
      });
    }
  }, [dispatch, orgSelected]);

  function ProcessWebApi() {
    dispatch({
      type: SG_EDIT_WEB_API_LOG,
      payload: {
        id: templateId,
        process_now: 1,

      }
    });
    setStartProcess(false)
  }

  function ImportWebApiDB() {
    dispatch({
      type: SG_EDIT_WEB_API_LOG,
      payload: {
        id: templateId,
        process_now: 2,
      }
    });
    setStartImport(false)
  }

  useEffect(() => {
    dispatch({
      type: SG_GET_ORGANIZATION_SELECT,
    });
  }, [dispatch]);

  const { get_web_api_log,
    get_org_cred_list,
    get_organization_select } = useSelector(
      (state) => ({
        get_web_api_log: state.web_api_log,
        get_org_cred_list: state.web_api_credential,
        get_organization_select: state.organizations.organization_select,
      }),
      shallowEqual
    );

  useEffect(() => {
    if (get_web_api_log?.[templateId]) {
      setTemplateDetails(get_web_api_log?.[templateId])
      setApiParam(get_web_api_log?.[templateId]?.param)
      setOrgSelected(get_organization_select.filter(sy => sy.id === get_web_api_log?.[templateId]?.organization)?.[0])
      setApiCredSelected(apiCreds.filter(sy => sy.id === get_web_api_log?.[templateId]?.web_api_credential))
    }
  }, [get_web_api_log, templateId, get_organization_select, apiCreds]);

  useEffect(() => {
    setLoading(get_web_api_log?.pending)
    if (get_web_api_log?.created?.id) {
      navigate(`/app/web-api-log/details/${get_web_api_log?.created?.id}`)
    }
    if (get_web_api_log?.updated?.id) {
      navigate(`/app/web-api-log/details/${get_web_api_log?.updated?.id}`)
    }
  }, [get_web_api_log, navigate]);



  useEffect(() => {
    setApiCreds(get_org_cred_list?.web_api_cred_list)
  }, [get_org_cred_list]);

  // console.log(get_web_api_log)
  // console.log(get_web_api_log?.pending)

  // useEffect(() => {
  //   // 
  //   if (!get_web_api_log?.pending) {
  //     const timer1 = setTimeout(() => {
  //       setLoading(get_web_api_log?.pending)
  //     }, 2000);
  //     return () => clearTimeout(timer1);
  //   }

  // }, [get_web_api_log?.pending]);

  // function DeleteQuestion() {
  //   if (templateId) {
  //     dispatch({
  //       type: SG_DELETE_EMPLOYEE_RECORD_LIST,
  //       payload: {
  //         id: templateId,
  //       }
  //     });
  //     navigate(`/app/employee-record-list?reload=true`)
  //   }
  // }

  // console.log(templateDetails)

  function UpdateQuestions() {
    setPristine(false)
    if (formErrors.length === 0) {
      if (!templateId) {

        dispatch({
          type: SG_ADD_WEB_API_LOG,
          payload: {
            organization: orgSelected?.id,
            web_api_credential: apiCredSelected?.id,
            param: apiParam,
          }
        });
      }
      setPristine(true)
    }
  }

  useEffect(() => {
    const ErrorList = []
    if (apiCredSelected?.direction > 10 &&
      apiCredSelected?.direction < 20) {
      if (!apiParam) {
        ErrorList.push("Enter a Param")
      }
    }

    if (!apiCredSelected?.id) {
      ErrorList.push("Select an Api")
    }
    if (!orgSelected?.id) {
      ErrorList.push("Select an Organization")
    }

    setFormErrors(ErrorList)
  }, [apiParam,
    apiCredSelected,
    orgSelected,
    valueChanged]);

  function ChangeCred(e) {
    setApiCredSelected(e)
    setValueChanged(e)
  }

  function ChangeOrg(e) {
    setApiCredSelected(false)
    setOrgSelected(e)
    setValueChanged(e)
  }

  // console.log(apiParam)
  // console.log(templateDetails)

  return (
    <>
      <Segment id="results" basic>
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>

        <Header as='h2'>
          <Icon
            style={{ cursor: "pointer" }}
            onClick={BackArrow} name='arrow left' />
          {templateId ? `Edit Details of ${templateDetails?.name}` : "Add New Api Pull"}
        </Header>
        {formErrors.length > 0 && !pristine &&
          <Message negative
            //   onDismiss={clearErrors}
            header='We need these details!'
            list={formErrors}
          />
        }
        <SectionDiv>
          <BasicErrorMessage />
        </SectionDiv>
        {templateId ?
          <SectionDiv>
            <Container>
              <List horizontal>

                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Name</List.Header>
                      {templateDetails?.web_api_credential_name}
                    </ListContainer>
                  </List.Content>
                </List.Item>

                {templateDetails?.param && <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Name</List.Header>
                      {templateDetails?.param}
                    </ListContainer>
                  </List.Content>
                </List.Item>
                }

                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Organization</List.Header>
                      {templateDetails?.organization_id ?
                        get_organization_select.filter(org => org.id === templateDetails?.organization_id)?.[0]?.name :
                        "For all Org"}
                    </ListContainer>
                  </List.Content>
                </List.Item>



                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Created</List.Header>
                      {templateDetails?.created_at && format(new Date(templateDetails?.created_at), 'dd-MMM-yyyy')}
                    </ListContainer>

                  </List.Content>
                </List.Item>

                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Status</List.Header>
                      {templateDetails?.status_text}
                    </ListContainer>
                  </List.Content>
                </List.Item>

                <List.Item>
                  <List.Content>
                    <ListContainer>
                      <List.Header>Process the API</List.Header>

                    </ListContainer>
                    {templateDetails?.status < 5 && <div>
                      <Button onClick={() => setStartProcess(true)}>Start Process</Button>
                      <Confirm
                        open={startProcess}
                        header='This would get the api response'
                        onCancel={() => setStartProcess(false)}
                        onConfirm={ProcessWebApi}
                      />
                    </div>}

                    {templateDetails?.status > 4 &&
                      templateDetails?.status < 9 && <div>
                        <Button onClick={() => setStartImport(true)}>
                          Start Import</Button>
                        <Confirm
                          open={startImport}
                          header='This would get the api response'
                          onCancel={() => setStartImport(false)}
                          onConfirm={ImportWebApiDB}
                        />
                      </div>}
                  </List.Content>
                </List.Item>

              </List>
            </Container>
          </SectionDiv>
          :

          <SectionDiv>


            <FormRow>
              <FormLabel>
                <LabelHead>Organization</LabelHead>
              </FormLabel>
              <FormInput>
                <Select
                  name="organization"
                  options={get_organization_select}
                  isClearable
                  onChange={(e) => ChangeOrg(e)}
                  getOptionLabel={(option) => `${option.name}`}
                  getOptionValue={(option) => `${option.id}`}
                  value={orgSelected}
                />
              </FormInput>
            </FormRow>


            <FormRow>
              <FormLabel>
                <LabelHead>API</LabelHead>
              </FormLabel>
              <FormInput>
                <Select
                  name="web_api_credential"
                  options={apiCreds}
                  isClearable
                  onChange={(e) => ChangeCred(e)}
                  getOptionLabel={(option) => `${option.name} (${option.type_of_value_text})`}
                  getOptionValue={(option) => `${option.id}`}
                  value={apiCredSelected}
                />
              </FormInput>
            </FormRow>

            {apiCredSelected?.direction > 10 &&
              apiCredSelected?.direction < 20 &&
              <FormRow>
                <FormLabel>
                  <LabelHead>Params</LabelHead>
                  <LabelHead>
                    {apiCreds?.[0]?.description}
                  </LabelHead>
                </FormLabel>
                <FormInput>
                  <Input
                    fluid
                    name="api_param"
                    defaultValue={apiParam}
                    onChange={(e) => setApiParam(e.target.value)}

                  />
                </FormInput>
              </FormRow>
            }


          </SectionDiv>

        }
        {!templateId &&
          <SectionDiv lastSection={true}>
            <Button floated='right'
              color="green"
              onClick={UpdateQuestions}>
              New Pull
            </Button>
          </SectionDiv>
        }

        <SectionDiv>

        </SectionDiv>
      </Segment>
    </>
  );
};

export default Details;

const SectionDiv = styled.div`
  padding: 10px 0px;
  margin:10px 0px;
  margin-bottom:${props => props.lastSection === true ? "100px" : "20px"};
`

const Container = styled.div`
  display: flex;
`
const SingleRowContent = styled.div`
  display: flex;
  // justify-content: flex-start;
  flex-direction: row;
`
const SectionCell = styled.div`
    padding: 10px 10px;
    min-width: 350px;
    display: flex;
    flex-direction: row;
`

const LabelHead = styled.label`
font-weight: 700;
display: block;

`

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  // background-color: #dcdcdcdc;
`
const FormRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: .5em;
`

const FormLabel = styled.div`
  padding: .5em 1em .5em 0;
  flex: 1;
`

const FormInput = styled.div`
  flex: 2;
`
