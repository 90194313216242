import React from 'react'
import { Routes, Route } from 'react-router-dom';

import { useNavigate, useLocation } from "react-router-dom";

import List from './List';
import AddEdit from './AddEdit';
import Details from './AddEdit';
import { Header, Grid, Button } from 'semantic-ui-react'

function EmployeeRecordList() {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <>

      <Grid.Row columns={2}>
        <Grid.Column>
          <Header as='h2' style={{ marginTop: "50px" }}>
            Web Api Pull Log
            <Header.Subheader>
              Api pull for getting data in the system.
            </Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          {(location.pathname !== '/app/web-api-log/new') &&
            <Button primary
              onClick={() => navigate(`/app/web-api-log/new`)}
            >Add New</Button>
          }

        </Grid.Column>
      </Grid.Row>
      <Routes>
        <Route path="/" element={<List />} />
        <Route path="new" element={<AddEdit />} />
        <Route path="details/:id" element={<Details />} />
      </Routes>
    </>
  );
}

export default EmployeeRecordList;
