

import {
    UPDATE_REPORT_TEMPLATE_PAGE_NUMBER,
    GET_REPORT_TEMPLATE_PENDING,
    GET_REPORT_TEMPLATE,
    SG_GET_REPORT_TEMPLATE,
    DELETE_REPORT_TEMPLATE,
    SG_DELETE_REPORT_TEMPLATE,
    FETCH_REPORT_TEMPLATE,
    SG_FETCH_REPORT_TEMPLATE,
    EDIT_REPORT_TEMPLATE,
    SG_EDIT_REPORT_TEMPLATE,
    ADD_REPORT_TEMPLATE,
    SG_ADD_REPORT_TEMPLATE,
    CLEAR_REPORT_TEMPLATE,
    CLEAR_ALL,
  } from "constants/actions";
  
  const initialState = {
    report_template: [],
    pending: false,
    updated: false,
    pageNumber: 1,
  };
  
  export default function ReportTemplate(state = initialState, action) {
    switch (action.type) {
      case UPDATE_REPORT_TEMPLATE_PAGE_NUMBER:
        return {
          ...state,
          pageNumber: action.payload,
          pending: false,
        };
      case GET_REPORT_TEMPLATE_PENDING:
        return {
          ...state,
          pending: true,
          updated: false,
        };
      case GET_REPORT_TEMPLATE:
        return {
          ...state,
          report_template: action.payload,
          pending: false,
          updated: false,
        };

      case FETCH_REPORT_TEMPLATE:
        return {
          ...state,
          [action.payload.id]: action.payload,
          pending: false,
          updated: false,
        };
  
  
      case EDIT_REPORT_TEMPLATE:
        return {
          ...state,
          report_template: [...state.report_template, action.payload],
          updated:  action.payload,
          pending: false,
        };
  
      case DELETE_REPORT_TEMPLATE:
        return {
          ...state,
          report_template: state.report_template.filter(
            (row) => row.id !== action.payload.id
          ),
          pending: false,
        };
  
      case ADD_REPORT_TEMPLATE:
        return {
          ...state,
          created: action.payload,
          report_template: [...state.report_template, action.payload],
          response: action.payload.response,
          pending: false,
        };
  
      case CLEAR_REPORT_TEMPLATE:
        return {
          ...state,
          ...initialState,
        };
  
      case CLEAR_ALL:
        return {
          ...state,
          ...initialState,
        };
  
      default:
        return state;
    }
  }
  