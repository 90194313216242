import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { format } from 'date-fns'

import { Card, Modal, Header, Button, Icon } from 'semantic-ui-react'
import {ContentDetail, Summary} from "./ContentDetail"
import FixSurveyEmpResponse from "./FixSurveyEmpResponse"
import MlFeedback from "./MlFeedback"
import ReactJson from "react-json-view";

const RowCard = ({ row, empUpdateRow, setEmpUpdateRow }) => {
//  empUpdateRow={true} setEmpUpdateRow={setEmpUpdateRow}
  const [openPortal, setOpenPortal] = useState(false)
  const [showResponse, setShowResponse] = useState(false)
  const [briefSummary, setBriefSummary] = useState(false)
  // const [briefToken, setbriefToken] = useState(false)
  function ShowResponse(resp) {
    // setbriefToken(id)
    setOpenPortal(true)
    setShowResponse(resp)
  }
  function ShowSummary(resp) {
    // setbriefToken(id)
    setOpenPortal(true)
    setBriefSummary(resp)
  }

  return (
    <>
      <Card fluid key={row?.id} color={row?.updated_response && 'orange'} >

        <Card.Content>

          <Container>
            <RowData>
            <ListContainer>
               
               <HeaderDiv>Id</HeaderDiv>
               <ContentDiv>
                 {row?.id}
               </ContentDiv>
           </ListContainer>
              <ListContainer>
               
                  <HeaderDiv>Survey Token</HeaderDiv>
                  <ContentDiv>
                    {row?.survey_token}
                  </ContentDiv>
              </ListContainer>

              <ListContainer>
                  {empUpdateRow &&
                  
                  <FixSurveyEmpResponse responseId={row.id} setEmpUpdateRow={setEmpUpdateRow}  />
                  }
              
              </ListContainer>
              {/* <ListContainer>
                  {empUpdateRow &&
                  
                  <MlFeedback debriefResponse={row} setEmpUpdateRow={setEmpUpdateRow}  />
                  }
              
              </ListContainer> */}

             {row?.updated_response &&
              <ListContainer highlight>
                <HeaderDiv>Modified Response</HeaderDiv>
                <ContentDiv>
                  {row?.response ?
                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => ShowResponse(row?.updated_response)}
                      color='violet' name='closed captioning' />
                    : "No response"}

                </ContentDiv>
              </ListContainer>}

              {row?.update_summary &&
              <ListContainer highlight>
                <HeaderDiv>Modified History</HeaderDiv>
                <ContentDiv>
                  {row?.response ?
                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => ShowSummary(row?.update_summary)}
                      color='violet' name='closed captioning' />
                    : "No response"}

                </ContentDiv>
              </ListContainer>}
            </RowData>
          </Container>
          <Container>
            <RowData>
              <ListContainer>
                <HeaderDiv>Completed Date</HeaderDiv>
                <ContentDiv>
                  {row?.updated_at && format(new Date(row?.updated_at), 'dd-MMM-yyyy hh:mm')}
                </ContentDiv>
              </ListContainer>

              <ListContainer>
                <HeaderDiv>Type of</HeaderDiv>
                <ContentDiv>
                  {row?.type_of_text}
                </ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Employee Id</HeaderDiv>
                <ContentDiv>
                  {row?.employee}
                </ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Feedback</HeaderDiv>
                <ContentDiv>
                  <TypesAndStatusArray serviceUsed={row?.response?.feedback} />
                </ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>Comments</HeaderDiv>
                <ContentDiv>
                  <TypesAndStatusArray serviceUsed={row?.response?.questions?.comments} />
                </ContentDiv>
              </ListContainer>
              <ListContainer>
                <HeaderDiv>View Response</HeaderDiv>
                <ContentDiv>
                  {row?.response ?
                    <Icon
                      style={{ cursor: "pointer" }}
                      onClick={() => ShowResponse(row?.response)}
                      color='violet' name='closed captioning' />
                    : "No response"}

                </ContentDiv>
              </ListContainer>
            </RowData>
          </Container>
        </Card.Content>
      </Card>

      <MoreDetails
        openPortal={openPortal}
        setOpenPortal={setOpenPortal}
        briefResponse={showResponse}
        briefSummary={briefSummary}
      />
    </>
  );
};

RowCard.propTypes = {
  row: PropTypes.object.isRequired,
};

export default RowCard;

function TypesAndStatus({ serviceUsed }) {

  return (
    <>
      {serviceUsed && serviceUsed ?
        <Icon color='green' name='checkmark' size='small' />
        :
        <Icon color='red' name='close' size='small' />
      }
    </>
  )
}

function TypesAndStatusArray({ serviceUsed }) {

  return (
    <>
      {serviceUsed && serviceUsed.length > 0 ?
        <Icon color='green' name='checkmark' size='small' />
        :
        <Icon color='red' name='close' size='small' />
      }
    </>
  )
}

const MoreDetails = ({ openPortal, setOpenPortal, briefResponse, briefSummary }) => {

  return (<Modal
    closeIcon
    open={openPortal}
    onClose={() => setOpenPortal(false)}
    onOpen={() => setOpenPortal(true)}
  >
    <Header>Detailed Response</Header>
    <Modal.Content>

      {briefResponse &&
      <ReactJson src={briefResponse} />
        // <ContentDetail briefResponse={briefResponse} />
      }
      {briefSummary && 
       <ReactJson src={briefSummary} />
        // <Summary briefChanggeHistory={briefSummary} />
      }
    </Modal.Content>
    <Modal.Actions>

      <Button color='purple' onClick={() => setOpenPortal(false)}>
        <Icon name='close' /> Close
      </Button>
    </Modal.Actions>
  </Modal>);
}

const ListContainer = styled.div`
  margin: 0px 25px;
  // padding: 0px 25px;
  padding: ${(props) => props.highlight? '5px' : '0px' };
  // background-color: #dcdcdcdc;
  background-color: ${(props) => props.highlight? '#dcdcdcdc' : '' };
`

const Container = styled.div`
  display: flex;
  margin: 10px 0;
`

const RowData = styled.div`
display: flex;
flex-direction: row;

`

const HeaderDiv = styled.div`
  // background-color: #b6701e;
  font-weight: bold;
`

const ContentDiv = styled.div`
  // background-color: #b6701e;

`