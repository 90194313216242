import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";

import { Link,useNavigate, useLocation } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { NavData } from "./navigation";

import {
  LOAD_EMPLOYEES,
  PULL_ORGANIZATION,
  SET_WHITE_LABEL,
} from "constants/actions";

import { white_label_data } from "data/white_label";

import InnerLogicLogo from "assets/images/logo.png";

// const WhiteLabel ={'OTP':{'image':IL,background:'rgba(0, 0, 0,0.9)',highlight:'rgb(227, 176, 31)'},
//                     'Resulta':{image:Resulta,background:'#434343',highlight:'#ff6b00'}}

const SubNav = (props) => {
  return (
    <Link to={props.data.link}>
      <Sub>
        <Text>{props.data.title}</Text>
      </Sub>
    </Link>
  );
};

const NavItem = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <NavContainer
      open={open && props.open && props.data.subNav}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <Link to={props.data.link}>
        <IconContainer
          selected={props.selected === props.data.link}
          highlight={props.highlight}
        >
          {props.data.image ? (
            <IconImage src={props.data.image} />
          ) : (
            <Icon
              name={props.data.icon}
              size={"small"}
              style={{ width: 30, fontSize: 15, margin: 0, marginLeft: -5 }}
            />
          )}
          <Text selected={props.selected === props.data.link}>
            {props.name ? props.name : props.data.title}
          </Text>
        </IconContainer>
      </Link>
      {props.data.subNav
        ? props.data.subNav.map((x, id) => {
          return <SubNav data={x} key={id} />;
        })
        : ""}

      {props.data.subNav && props.open ? (
        <Expand open={open}>
          <Icon
            name={"chevron down"}
            size={"small"}
            onClick={() => setOpen(!open)}
          />
        </Expand>
      ) : (
        ""
      )}
    </NavContainer>
  );
};

const SideBar = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);

  const [accountId, setAccountId] = useState(1);
  const [roleId, setRoleId] = useState(0);
  const [employee, setEmployee] = useState(false);
  const [organization, setOrganization] = useState(false);
  const [organizationId, setOrganizationId] = useState(false);
  const [validRoutes, setValidRoutes] = useState([]);
  const [loading, setLoading] = useState(true);

  const [organizationLogo, setOrganizationLogo] =
    React.useState(InnerLogicLogo);

  const EmployeeId = useSelector(
    (state) => state.auth?.employee_id,
    shallowEqual
  );
  const AmbassadorId = useSelector(
    (state) => state.auth?.ambassador_id,
    shallowEqual
  );
  const WhiteLabel = useSelector(
    (state) => state.white_label?.white_label,
    shallowEqual
  );


  const { get_auth, get_employees, get_organizations, get_selectedOrg } =
    useSelector(
      (state) => ({
        get_auth: state.auth,
        get_employees: state.employees,
        get_error: state.errors,
        get_organizations: state.organizations,
        get_selectedOrg: state.selectedOrg,
      }),
      shallowEqual
    );

  useEffect(() => {
    // if the response is ok 200
    if (Number(get_auth?.organization_id) > 0) {
      dispatch({
        type: PULL_ORGANIZATION,
        payload: { id: get_auth.organization_id },
      });
    }
    dispatch({ type: LOAD_EMPLOYEES }); // from server
    // dispatch({ type: LOAD_TEAMS });
    dispatch({
      type: SET_WHITE_LABEL,
      payload: white_label_data["Nuwave"],
    });
  }, [dispatch, get_auth]);

  useEffect(() => {
    setOrganizationLogo(organization?.logo);
  }, [organization]);

  useEffect(() => {
    if (get_auth?.token) {
      if (Number(EmployeeId)) {
        // setAccountId(get_employees[get_auth.employee_id]?.account_type);
        setRoleId(get_employees[get_auth.employee_id]?.role);

        setEmployee(get_employees[get_auth.employee_id]);
        setOrganization(get_organizations[get_auth.organization_id]);
        setOrganizationId(get_auth.organization_id);
      }
      if (Number(AmbassadorId)) {
        setAccountId(5);
        // setRoleId(5);
        // setEmployee(get_employees[get_auth.employee_id])
        setOrganization(get_selectedOrg?.organization);
        setOrganizationId(get_selectedOrg?.organization?.id);
      }
      // console.log(get_employees[get_auth.employee_id])
    }
  }, [
    get_employees,
    get_auth,
    get_organizations,
    AmbassadorId,
    EmployeeId,
    get_selectedOrg,
  ]);

  useEffect(() => {
    if (organization?.styling) {
      dispatch({
        type: SET_WHITE_LABEL,
        payload: organization.styling,
      });
    }
  }, [organization, dispatch]);

  useEffect(() => {
    // if (get_auth?.token) {
    const currentPath = location.pathname;
    setSelected(currentPath);
    // }
  }, [location]);

  useEffect(() => {
    const EnabledList = [];
    EnabledList.push("MainMenu", "SubMenu", "Common");
    setValidRoutes(EnabledList);
    const timer1 = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [organization]);

  console.log(
    "organization?.styling?.background",
    organization?.styling?.background,
    "WhiteLabel.background",
    WhiteLabel.background);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 999,
        backgroundColor: "white",
      }}
    >
      <Bar
        open={open}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        grains={
          organization?.styling?.nav_background
            ? organization?.styling?.nav_background
            : false
        }
      >
        <LogoContainer>
          <Logo src={organizationLogo} />
        </LogoContainer>

        {NavData.map((item, i) => {
          // console.log(item)
          // console.log("My roleId", roleId)
          if (!validRoutes.includes(item.group)) {
            return null;
          }
         
          // no much of value of roles
          // outside of team tool
          // if (item.role < roleId && validRoutes.includes("MainMenu")) {
          //   return null;
          // }

          if (item.header) {
            return <Header open={open} key={i}>{item.header}</Header>;
          }

          

          return (
            <NavItem
              data={item}
              organization={organization}
              selected={selected}
              open={open}
              key={i}
              highlight={
                organization?.styling?.highlight
                  ? organization?.styling?.highlight
                  : WhiteLabel.highlight
              }
            />
          );
        })}
      </Bar>
    </div>
  );
};

export default SideBar;

const Bar = styled.div`
  width: ${(props) => (props.open ? "225px" : "50px")};
  box-shadow: 0 2px 40px 0 rgba(205, 205, 205, 0.5);
  margin: 0px;
  display: flex;
  flex-direction: column;
  z-index: 99999;
  box-sizing: content-box;
  position: relative;
  transition: width 400ms ease;
  overflow: hidden;
  padding-bottom: 100px;
  padding-top: 10px;
  background-color: black;};
  height: 100vh;

  background-image: ${(props) =>
    props.grains ? "radial-gradient(#424242 10%, transparent 70%)" : ""};
  background-position: ${(props) => (props.grains ? "0 0, 50px 50px" : "")};
  background-size: ${(props) => (props.grains ? "2px 2px" : "")};
`;
const Logo = styled.img`
  width: 40px;
  margin-right: 15px;
`;

const LogoContainer = styled.div`
  font-size: 35px;
  width: 175px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 6.5px;
  padding-bottom: 10px;
`;

const Avatar = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 25px;
  position: absolute;
  bottom: 67px;
`;
const IconContainer = styled.div`
  color: white;
  font-size: 25px;
  width: 225px;
  background-color: ${(props) => (props.selected ? props.highlight : "none")};
  padding-left: 18px;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 3px;
  height: 25px;
`;

const Text = styled.div`
  font-family: "Barlow", sans-serif;
  font-size: 12px;
  margin-left: 15px;
  color: white;
`;

const Innerlogic = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin-left: 10px;
  color: white;
`;

const Sub = styled.div`
  width: 100%;
  width: 225px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  font-family: "Red Hat Display", sans-serif;
`;

const NavContainer = styled.div`
  position: relative;
  max-height: ${(props) => (props.open ? "300px" : "25px")};
  overflow: hidden;
  transition: max-height 1s ease;
  width: 100%;
`;

const Expand = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  transform: ${(props) => (props.open ? "rotate(180deg)" : "rotate(0)")};
  transition: transform 300ms;
  cursor: pointer;
  color: white;
`;

const IconImage = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 6px;
  color: white;
`;

const Header = styled.div`
  color: white;
  font-size: 12px;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: bold;
  width: 50px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
