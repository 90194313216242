import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
    GET_REPORT_TEMPLATE_PENDING,
    GET_REPORT_TEMPLATE,
    SG_GET_REPORT_TEMPLATE,
    DELETE_REPORT_TEMPLATE,
    SG_DELETE_REPORT_TEMPLATE,
    FETCH_REPORT_TEMPLATE,
    SG_FETCH_REPORT_TEMPLATE,
    EDIT_REPORT_TEMPLATE,
    SG_EDIT_REPORT_TEMPLATE,
    ADD_REPORT_TEMPLATE,
    SG_ADD_REPORT_TEMPLATE,
  ALL_ERRORS,
} from "constants/actions";


function* loadmethods(action) {
    yield put({ type: GET_REPORT_TEMPLATE_PENDING });

  try {
    const json = yield call(api.GET_REPORT_TEMPLATE, action.payload);
    yield put({ type: GET_REPORT_TEMPLATE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodLoad() {
  yield takeLatest(SG_GET_REPORT_TEMPLATE, loadmethods);
}


function* fetchmethods(action) {
  yield put({ type: GET_REPORT_TEMPLATE_PENDING });
  try {
    const json = yield call(api.FETCH_REPORT_TEMPLATE, action.payload);
    yield put({ type: FETCH_REPORT_TEMPLATE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_REPORT_TEMPLATE, fetchmethods);
}

function* updatemethods(action) {
  yield put({ type: GET_REPORT_TEMPLATE_PENDING });
  try {
    const json = yield call(api.EDIT_REPORT_TEMPLATE, action.payload);
    yield put({ type: EDIT_REPORT_TEMPLATE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodUpdate() {
  yield takeLatest(SG_EDIT_REPORT_TEMPLATE, updatemethods);
}


function* addmethods(action) {
  yield put({ type: GET_REPORT_TEMPLATE_PENDING });
  try {
    const json = yield call(api.ADD_REPORT_TEMPLATE, action.payload);
    yield put({ type: ADD_REPORT_TEMPLATE, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodAdd() {
  yield takeLatest(SG_ADD_REPORT_TEMPLATE, addmethods);
}

function* deletemethods(action) {
  yield put({ type: GET_REPORT_TEMPLATE_PENDING });
  try {
    yield call(api.DELETE_REPORT_TEMPLATE, action.payload);
    yield put({ type: DELETE_REPORT_TEMPLATE, payload: action.payload });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_REPORT_TEMPLATE, deletemethods);
}


export default function* index() {
  yield all([
    methodLoad(),
    methodFetch(),
    methodUpdate(),
    methodAdd(),
    methodDelete(),
  ]);
}
